<template>
  <div
    id="projects"
    class="cd-section"
  >
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/img/header.jpg')"
      />
      <div class="content-center">
        <div class="container">
          <TypingTitle :titles="['Some of Our Projects']" />
        </div>
      </div>
    </div>
    <div
      class="projects-3"
      data-background-color="seus-green"
    >
      <div class="container">
        <div class="content-big-screen">
          <div class="section-story-overview">
            <div class="row yuri">
              <div class="col-md-6">
                <div
                  class="image-container image-left"
                  style="background-image: url('img/projects/IMG-20241022-WA0009.jpg')"
                >
                  <p class="blockquote blockquote-primary">
                    Independent review/assessment of the impact of graphite mining on Lilongwe water board’s raw water source at Malingunde in Malawi.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="image-container image-right"
                  style="background-image: url('img/projects/IMG-20241022-WA0015.jpg')"
                />
              </div>
            </div>
          </div>
          <div class="section-story-overview yuri">
            <div class="row yuri">
              <div class="col-md-6">
                <div
                  class="image-container image-left"
                  style="background-image: url('img/projects/seus/Environmental compliance/IMG-20241022-WA0017.jpg')"
                >
                  <p class="blockquote blockquote-primary">
                    Environmental and social compliance for the construction works for the rehabilitation and widening of the M1 road from Kamuzu International Airport (KIA) turn-off to Kasungu.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="image-container image-right"
                  style="background-image: url('img/projects/seus/Environmental compliance/IMG-20241022-WA0013.jpg')"
                />
              </div>
            </div>
          </div>
          <div class="section-story-overview">
            <div class="row yuri">
              <div class="col-md-6">
                <div
                  class="image-container image-left"
                  style="background-image: url('img/projects/seus/Survey on recycling/9.jpg')"
                >
                  <p class="blockquote blockquote-primary">
                    Survey on recycling market in Lusaka city for the Lusaka clean city project – SEUS consult / JICA.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="image-container image-right"
                  style="background-image: url('img/projects/seus/Survey on recycling/Broker in Ngombe.jpg')"
                />
              </div>
            </div>
          </div>
          <div class="section-story-overview">
            <div class="row yuri">
              <div class="col-md-6">
                <div
                  class="image-container image-left"
                  style="background-image: url('/img/projects/seus/Environmwtal impact and social/IMGWA0079(1).jpg')"
                >
                  <p class="blockquote blockquote-primary">
                    Environmental & social impact assessment for the proposed solar power plant project in Kafue district by Lusanje solar power ltd.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="image-container image-right"
                  style="background-image: url('/img/projects/seus/Environmwtal impact and social/IMG-20211214).jpg')"
                />
              </div>
            </div>
          </div>
          <div class="section-story-overview">
            <div class="row yuri">
              <div class="col-md-6">
                <div
                  class="image-container image-left"
                  style="background-image: url('img/projects/seus/SOCIAL_IMPACT_ASSESSMENT/9.jfif.jpg')"
                >
                  <p class="blockquote blockquote-primary">
                    Environmental & social impact assessment for the proposed 100 MW solar power project by CVPP solar company limited.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="image-container image-right"
                  style="background-image: url('img/projects/seus/SOCIAL_IMPACT_ASSESSMENT/13.jfif.jpg')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="content-small-screen">
          <div class="project-container">
            <img
              src="img/projects/IMG-20241022-WA0009.jpg"
              alt="Graphite Mining Assessment"
            >
            <p>Independent review/assessment of the impact of graphite mining on Lilongwe water board’s raw water source at Malingunde in Malawi.</p>
          </div>

          <div class="project-container">
            <img
              src="img/projects/seus/Environmental compliance/IMG-20241022-WA0017.jpg"
              alt="Environmental Compliance M1 Road"
            >
            <p>Environmental and social compliance for the construction works for the rehabilitation and widening of the M1 road from Kamuzu International Airport (KIA) turn-off to Kasungu.</p>
          </div>

          <div class="project-container">
            <img
              src="img/projects/seus/Survey on recycling/9.jpg"
              alt="Survey on Recycling Market in Lusaka"
            >
            <p>Survey on recycling market in Lusaka city for the Lusaka clean city project – SEUS consult / JICA.</p>
          </div>

          <div class="project-container">
            <img
              src="img/projects/seus/Environmwtal impact and social/IMG-20211214).jpg"
              alt="Solar Power Plant Project Kafue"
            >
            <p>Environmental & social impact assessment for the proposed solar power plant project in Kafue district by Lusanje solar power ltd.</p>
          </div>

          <div class="project-container">
            <img
              src="img/projects/seus/SOCIAL_IMPACT_ASSESSMENT/9.jfif.jpg"
              alt="100 MW Solar Power Project"
            >
            <p>Environmental & social impact assessment for the proposed 100 MW solar power project by CVPP solar company limited.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@/components';
import TypingTitle from '@/components/TypingTitle';

export default {
  components: {
    [Button.name]: Button,
    TypingTitle
  },
};
</script>

<style>
</style>
